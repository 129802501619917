export class AnimatorState2DScript {
    /**
     * 动画状态开始时执行。
     */
    onStateEnter(): void {

    }

    /**
     * 动画状态更新时执行。
     */
    onStateUpdate(): void {

    }

    /**
     * 动画状态退出时执行。
     */
    onStateExit(): void {

    }
}